import React from "react"
import ReactTooltip from "react-tooltip"
import "./InfoTooltip.less"

const InfoTooltip = () => {
  const tooltipText =
    "Þegar verið er að leita eftir orði ber að varast að leitarvélin er hástafa og lágstafa næm.<br />" +
    "Leitarvélin inniheldur orðabók svo þegar set er inn orðið “hestur” þá er leitað eftir hestur í öllum fallbeyingum og með greini.<br /><br />" +
    "Til að víkka leitarskilyrðin – skrifaðu | milli orða og leitaðu að öllum fréttum sem innihalda eitthvað af völdum orðum.<br /><br />" +
    "Til að þrengja leit – skrifaðu + milli orða og leitaðu að öllum fréttum sem innihalda öll leitarorð.<br /><br />" +
    "Til að undanskilja orð – skrifaðu mínus táknið „-“ fyrir framan orð til að leita aðeins að fréttum sem innihalda ekki það orð.<br /><br />" +
    "Til að leita að ólíkum endingum orða – skrifaðu * fyrir aftan fremsta hluta orðs til að finna allar fréttir<br />" +
    "sem innihalda allar mögulegar endingar viðkomandi orðs. Dæmi: Lögreglu* leitar að lögreglubíll, lögreglumaður osfrv.<br /><br />" +
    "Frasar eða orðaröð – Notaðu gæsalappir þegar orðaröð skiptir máli. Dæmi: „stefnuræða forsætisráðherra“"

  return (
    <div>
      <i
        className="icon-info2"
        data-tip={tooltipText}
        style={{ cursor: "pointer", fontSize: "18px" }}
      />
      <ReactTooltip
        place="left"
        html={true}
        offset={{ top: -80, left: 20 }}
        className="left-aligned-tooltip"
      />
    </div>
  )
}

export default InfoTooltip
